import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import CategoryPage from "./pages/CategoryPage";
import DetailPage from "./pages/DetailPage";
import CategoriesPage from "./pages/CategoriesPage";
import Contact from "./routes/Contact";
import About from "./routes/About";



function App() {
  return (
    <Router>
      <Routes>
        {/* Main Page */}
        <Route path="/" element={<Home/>} />
        {/* Categories Page */}
        <Route path="/categories" element={<CategoriesPage />} />
        {/* About Page */}
        <Route path='/about' element={<About />} />
        {/* Contact Page */}
        <Route path='/contact' element={<Contact />} />
        {/* Category Page */}
        <Route path="/:category" element={<CategoryPage />} />
        
        {/* Detail Page */}
        <Route path="/:category/:slug" element={<DetailPage />} />
      </Routes>
    </Router>
  );
}

export default App;
