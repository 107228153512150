import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { mainindex } from "../data/mainindex";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./CategoryPage.css";

const CategoryPage = () => {
  const { category } = useParams();
  const [pages, setPages] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      // Dynamically import the category index
      const categoryModule = require(`../data/categories/${category}/${category}Index`);
      const categoryPages = categoryModule[`${category}Index`]; // Access the correct export
      setPages(categoryPages); // Set pages for rendering
    } catch (err) {
      setError("Pages data not found for this category");
    }
  }, [category]);

  const categoryData = mainindex.find((cat) => cat.slug === category);

  if (!categoryData) {
    return <h1>Category not found</h1>;
  }

  if (error) {
    return <h1>{error}</h1>;
  }

  if (!pages) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <Navbar />
      <div className="category-page">
        <h1>{categoryData.name}</h1>
        <p>{categoryData.description}</p>
        <div className="cards-container">
          {pages.map((page) => (
            <div key={page.slugNumber} className="card">
              <h2>{page.title}</h2>
              <p>{page.description}</p>
              <Link to={`/${category}/${page.slug}`} className="btn">
                Explore
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CategoryPage;
