import { Link } from "react-router-dom";
import "./AboutCon.css";
import React from "react";
const AboutCon = () => {
    return <div className="about">
        <div className="left">
            <h1>Embark on This Adventure with Us</h1>
            <p>Join us as we uncover the top contenders in every category and celebrate the best of everything. Thank you for visiting TheTopTen!</p>
            <Link to="/contact">
                <button className="btn">Contact</button>
            </Link>
        </div>
        <div className="right">
            <div className="img-container">
                <div className="img-stack top">
                    <img src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"  className="img" alt="true" />
                </div>
                <div className="img-stack bottom">
                    <img  src="https://images.unsplash.com/photo-1482440308425-276ad0f28b19?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"  className="img" alt="true" />
                </div>
            </div>
        </div>
    </div>
};
export default AboutCon;