import React from "react";
import "../styles/components/Card.css";
import "./Card.css";

const Card = ({ name, source, description, rank }) => {
  // Function to parse the source string and extract parts
  const parseSource = (source) => {
    if (!source) return {};
  
    // Parse the string as HTML to extract details
    const parser = new DOMParser();
    const doc = parser.parseFromString(source, "text/html");
    const anchor = doc.querySelector("a");
    const img = doc.querySelector("img");
  
    if (!anchor || !img) return {};
  
    // Extract parts
    const title = anchor.getAttribute("title");
    const titleParts = title?.split(", ") || [];
    const titlePart = titleParts[0]; // The source name, e.g., "Bollywood Hungama"
  
    // Regex to match any CC license type, including CC BY, CC BY-SA, CC BY-NC-SA, etc.
    const licenseMatch = title.match(/CC BY(?:-[A-Z]+)? [\d\.]+/);
    const licenseText = licenseMatch ? licenseMatch[0] : null; // License text, e.g., "CC BY-SA 4.0"
  
    // Regex to extract the license link
    const licenseLinkMatch = title.match(/https:\/\/creativecommons\.org\/licenses\/[a-z\-]+\/[\d\.]+/);
    const licenseLink = licenseLinkMatch ? licenseLinkMatch[0] : null; // License link
  
    const platformPart = titleParts[titleParts.length - 1]; // Platform, e.g., "via Wikimedia Commons"
    const imageSrc = img.getAttribute("src"); // Image URL
    const altText = img.getAttribute("alt"); // Alt text for the image
  
    return {
      titlePart,
      licenseText,
      licenseLink,
      platformPart,
      imageSrc,
      altText,
    };
  };
  

  // Parsed source data
  const {
    titlePart,
    licenseText,
    licenseLink,
    platformPart,
    imageSrc,
    altText,
  } = parseSource(source);

  return (
    <div className="project-card">   {/*  card */}
      {/* Rank and Name */}
      <h2 className="project-title">{rank}. {name}</h2>   {/*  card-rank */}

      {/* Image */}
      {imageSrc && (
        <div className="card-image-container">
          <img src={imageSrc} alt={altText} className="card-image" />
        </div>
      )}

      {/* Alt Text */}
      {altText && <p className="card-alt-text">{altText}</p>}

      {/* Attribution Details */}
      <div className="card-source">
        {/* Source Title */}
        {titlePart && <p className="title-part">Source: {titlePart}</p>}

        {/* License */}
        {licenseLink && licenseText && (
          <p>
            License:{" "}
            <a href={licenseLink} target="_blank" rel="noopener noreferrer">
              {licenseText}
            </a>
          </p>
        )}

        {/* Platform */}
        {platformPart && <p>{platformPart}</p>}
      </div>

      {/* Description */}
      <p className="card-description">{description}</p>
    </div>
  );
};

export default Card;
