export const sportsIndex = [
  {
    "slugNumber": 1,
    "title": "Top Female Tennis Players",
    "slug": "top-female-tennis-players",
    "description": "List of Top Female Tennis Players"
  },
  {
    "slugNumber": 2,
    "title": "Top Tennis Players",
    "slug": "top-tennis-players",
    "description": "List of top Tennis Players "
  }
];