import React from "react";
import { Link } from "react-router-dom";
import { mainindex } from "../data/mainindex";
import "./CategoriesPage.css";
import Navbar from './../components/Navbar';
import Footer from './../components/Footer';
import HeroImg1 from "../components/HeroImg1";
const CategoriesPage = () => {
  return (
    <>
    <Navbar/>
    <HeroImg1 heading="Explore Categories" text="Discover a wide range of topics and uncover the top ten in each category!" />
    <div className="categories-page">
      <div className="categories-grid">
        {mainindex.map((category) => (
          <div className="category-card" key={category.slugNumber}>
            <h2>{category.name}</h2>
            <p>{category.description}</p>
            <Link to={`/${category.slug}`} className="btn">
              Explore {category.name}
            </Link>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default CategoriesPage;
