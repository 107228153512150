import React from "react";
import "./HomeContent.css";
import { Link } from "react-router-dom";
import IntroImage from "../assets/pattern.jpg";

const HomeContent = ({ children }) => {
  return (
    <div className="hero">
      <div className="mask">
        <img className="into-img" src={IntroImage} alt="IntroImg" />
      </div>
      <div className="content">
        {/* <main className="main-content">
          <h1>Welcome !! Home </h1>
          {children}
        </main> */} 
        <p>Discover the ultimate Top 10 lists across every category, from Anime Characters to Tech Innovations and Sports Legends!</p>
        <h1>theTopten.in</h1>
        
        <div>
          <Link to="/categories" className="btn">
            Categories
          </Link>
          <Link to="/contact" className="btn btn-light">
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
