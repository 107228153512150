// import React from 'react';
// import '../styles/components/Footer.css';
// // Assuming you have a CSS file for styling

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <p>&copy; {new Date().getFullYear()} Top Ten List. All Rights Reserved.</p>
//     </footer>
//   );
// };

// export default Footer;


import "./Footer.css"
import React from "react"
import { FaTwitter, FaFacebook, FaHome, FaLinkedin, FaMailBulk, FaPhone } from "react-icons/fa";
const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="left">
                    <div className="location">
                        <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
                        <div>
                            <p>Anna Nagar , Chennai</p>
                            <p>Tamil Nadu</p>
                        </div>
                    </div>
                    <div className="phone">
                        <h4><FaPhone size={20} style={{ color: "#fff", marginRight: "2rem" }} />
                            939XXXXX56</h4>
                    </div>
                    <div className="email">
                        <h4><FaMailBulk size={20} style={{ color: "#fff", marginRight: "2rem" }} />
                        thetopten.in.14@gmail.com</h4>
                    </div>
                </div>
                <div className="right">
                    <h4>About the Company</h4>
                    <p>Discover the best in every field with curated top ten lists on TheTopTen – your go-to source for reliable, up-to-date information.</p>
                    <div className="social">
                        <FaFacebook size={30} style={{ color: "#fff", marginRight: "1rem" }} />
                        <FaTwitter size={30} style={{ color: "#fff", marginRight: "1rem" }} />
                        <FaLinkedin size={30} style={{ color: "#fff", marginRight: "1rem" }} />
                    </div>
                </div>
            </div>
            <p className="allrightsreserved">&copy; {new Date().getFullYear()} theTopten.in All Rights Reserved.</p>
        </div>
    )
}
export default Footer;