import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg1 from "../components/HeroImg1";
import AboutCon from "../components/AboutCon";
const About = () => {
    return <div>
        <Navbar />
        <HeroImg1 heading="About Us." text="We deliver well-researched content with descriptions, images, and sources to ensure reliable insights." />
        <AboutCon />
        <Footer />
    </div>
}
export default About;