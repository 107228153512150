export const collegesIndex = [
  {
    "slugNumber": 1,
    "title": "Top Engineering Colleges in Chennai",
    "slug": "engineering-chennai",
    "description": "List of Top Engineering Colleges in Chennai"
  },
  {
    "slugNumber": 2,
    "title": "Top Engineering Colleges in New Delhi",
    "slug": "engineering-new-delhi",
    "description": "List of Top Engineering Colleges in New Delhi"
  },
  {
    "slugNumber": 3,
    "title": "Top Engineering Colleges in Mumbai",
    "slug": "engineering-mumbai",
    "description": "List of Top Engineering Colleges in Mumbai"
  },
  {
    "slugNumber": 4,
    "title": "Top Engineering Colleges in Kanpur",
    "slug": "engineering-kanpur",
    "description": "List of Top Engineering Colleges in Kanpur"
  },
  {
    "slugNumber": 5,
    "title": "Top Engineering Colleges in Kharagpur",
    "slug": "engineering-kharagpur",
    "description": "List of Top Engineering Colleges in Kharagpur"
  },
  {
    "slugNumber": 6,
    "title": "Top Engineering Colleges in Roorkee",
    "slug": "engineering-roorkee",
    "description": "List of Top Engineering Colleges in Roorkee"
  },
  {
    "slugNumber": 7,
    "title": "Top Engineering Colleges in Guwahati",
    "slug": "engineering-guwahati",
    "description": "List of Top Engineering Colleges in Guwahati"
  },
  {
    "slugNumber": 8,
    "title": "Top Engineering Colleges in Hyderabad",
    "slug": "engineering-hyderabad",
    "description": "List of Top Engineering Colleges in Hyderabad"
  },
  {
    "slugNumber": 9,
    "title": "Top Engineering Colleges in Tiruchirappalli",
    "slug": "engineering-tiruchirappalli",
    "description": "List of Top Engineering Colleges in Tiruchirappalli"
  },
  {
    "slugNumber": 10,
    "title": "Top Engineering Colleges in Varanasi",
    "slug": "engineering-varanasi",
    "description": "List of Top Engineering Colleges in Varanasi"
  },
  {
    "slugNumber": 11,
    "title": "Top Engineering Colleges in Vellore",
    "slug": "engineering-vellore",
    "description": "List of Top Engineering Colleges in Vellore"
  },
  {
    "slugNumber": 12,
    "title": "Top Engineering Colleges in Kolkata",
    "slug": "engineering-kolkata",
    "description": "List of Top Engineering Colleges in Kolkata"
  },
  {
    "slugNumber": 13,
    "title": "Top Engineering Colleges in Dhanbad",
    "slug": "engineering-dhanbad",
    "description": "List of Top Engineering Colleges in Dhanbad"
  },
  {
    "slugNumber": 14,
    "title": "Top Engineering Colleges in Indore",
    "slug": "engineering-indore",
    "description": "List of Top Engineering Colleges in Indore"
  },
  {
    "slugNumber": 15,
    "title": "Top Engineering Colleges in Surathkal",
    "slug": "engineering-surathkal",
    "description": "List of Top Engineering Colleges in Surathkal"
  },
  {
    "slugNumber": 16,
    "title": "Top Engineering Colleges in Gandhinagar",
    "slug": "engineering-gandhinagar",
    "description": "List of Top Engineering Colleges in Gandhinagar"
  },
  {
    "slugNumber": 17,
    "title": "Top Engineering Colleges in Rourkela",
    "slug": "engineering-rourkela",
    "description": "List of Top Engineering Colleges in Rourkela"
  },
  {
    "slugNumber": 18,
    "title": "Top Engineering Colleges in Pilani",
    "slug": "engineering-pilani",
    "description": "List of Top Engineering Colleges in Pilani"
  },
  {
    "slugNumber": 19,
    "title": "Top Engineering Colleges in Warangal",
    "slug": "engineering-warangal",
    "description": "List of Top Engineering Colleges in Warangal"
  },
  {
    "slugNumber": 20,
    "title": "Top Engineering Colleges in Rupnagar",
    "slug": "engineering-rupnagar",
    "description": "List of Top Engineering Colleges in Rupnagar"
  },
  {
    "slugNumber": 21,
    "title": "Top Engineering Colleges in Coimbatore",
    "slug": "engineering-coimbatore",
    "description": "List of Top Engineering Colleges in Coimbatore"
  },
  {
    "slugNumber": 22,
    "title": "Top Engineering Colleges in Kozhikode",
    "slug": "engineering-kozhikode",
    "description": "List of Top Engineering Colleges in Kozhikode"
  },
  {
    "slugNumber": 23,
    "title": "Top Engineering Colleges in Bhubaneswar",
    "slug": "engineering-bhubaneswar",
    "description": "List of Top Engineering Colleges in Bhubaneswar"
  },
  {
    "slugNumber": 24,
    "title": "Top Engineering Colleges in Jodhpur",
    "slug": "engineering-jodhpur",
    "description": "List of Top Engineering Colleges in Jodhpur"
  },
  {
    "slugNumber": 25,
    "title": "Top Engineering Colleges in Patiala",
    "slug": "engineering-patiala",
    "description": "List of Top Engineering Colleges in Patiala"
  },
  {
    "slugNumber": 26,
    "title": "Top Engineering Colleges in Gautam Budh Nagar",
    "slug": "engineering-gautam-budh-nagar",
    "description": "List of Top Engineering Colleges in Gautam Budh Nagar"
  },
  {
    "slugNumber": 27,
    "title": "Top Engineering Colleges in Mandi",
    "slug": "engineering-mandi",
    "description": "List of Top Engineering Colleges in Mandi"
  },
  {
    "slugNumber": 28,
    "title": "Top Engineering Colleges in Mohali",
    "slug": "engineering-mohali",
    "description": "List of Top Engineering Colleges in Mohali"
  },
  {
    "slugNumber": 29,
    "title": "Top Engineering Colleges in Aligarh",
    "slug": "engineering-aligarh",
    "description": "List of Top Engineering Colleges in Aligarh"
  },
  {
    "slugNumber": 30,
    "title": "Top Engineering Colleges in Patna",
    "slug": "engineering-patna",
    "description": "List of Top Engineering Colleges in Patna"
  },
  {
    "slugNumber": 31,
    "title": "Top Engineering Colleges in Vaddeswaram",
    "slug": "engineering-vaddeswaram",
    "description": "List of Top Engineering Colleges in Vaddeswaram"
  },
  {
    "slugNumber": 32,
    "title": "Top Engineering Colleges in Srivilliputhur",
    "slug": "engineering-srivilliputhur",
    "description": "List of Top Engineering Colleges in Srivilliputhur"
  },
  {
    "slugNumber": 33,
    "title": "Top Engineering Colleges in Thanjavur",
    "slug": "engineering-thanjavur",
    "description": "List of Top Engineering Colleges in Thanjavur"
  },
  {
    "slugNumber": 34,
    "title": "Top Engineering Colleges in Nagpur",
    "slug": "engineering-nagpur",
    "description": "List of Top Engineering Colleges in Nagpur"
  },
  {
    "slugNumber": 35,
    "title": "Top Engineering Colleges in Silchar",
    "slug": "engineering-silchar",
    "description": "List of Top Engineering Colleges in Silchar"
  },
  {
    "slugNumber": 36,
    "title": "Top Engineering Colleges in Dehradun",
    "slug": "engineering-dehradun",
    "description": "List of Top Engineering Colleges in Dehradun"
  },
  {
    "slugNumber": 37,
    "title": "Top Engineering Colleges in Jaipur",
    "slug": "engineering-jaipur",
    "description": "List of Top Engineering Colleges in Jaipur"
  },
  {
    "slugNumber": 38,
    "title": "Top Engineering Colleges in Durgapur",
    "slug": "engineering-durgapur",
    "description": "List of Top Engineering Colleges in Durgapur"
  },
  {
    "slugNumber": 39,
    "title": "Top Engineering Colleges in Delhi",
    "slug": "engineering-delhi",
    "description": "List of Top Engineering Colleges in Delhi"
  },
  {
    "slugNumber": 40,
    "title": "Top Engineering Colleges in Kalavakkam",
    "slug": "engineering-kalavakkam",
    "description": "List of Top Engineering Colleges in Kalavakkam"
  },
  {
    "slugNumber": 41,
    "title": "Top Engineering Colleges in Ranchi",
    "slug": "engineering-ranchi",
    "description": "List of Top Engineering Colleges in Ranchi"
  },
  {
    "slugNumber": 42,
    "title": "Top Engineering Colleges in Howrah",
    "slug": "engineering-howrah",
    "description": "List of Top Engineering Colleges in Howrah"
  },
  {
    "slugNumber": 43,
    "title": "Top Engineering Colleges in Phagwara",
    "slug": "engineering-phagwara",
    "description": "List of Top Engineering Colleges in Phagwara"
  },
  {
    "slugNumber": 44,
    "title": "Top Engineering Colleges in Thiruvananthapuram",
    "slug": "engineering-thiruvananthapuram",
    "description": "List of Top Engineering Colleges in Thiruvananthapuram"
  },
  {
    "slugNumber": 45,
    "title": "Top Engineering Colleges in Shillong",
    "slug": "engineering-shillong",
    "description": "List of Top Engineering Colleges in Shillong"
  },
  {
    "slugNumber": 46,
    "title": "Top Engineering Colleges in Belgaum",
    "slug": "engineering-belgaum",
    "description": "List of Top Engineering Colleges in Belgaum"
  },
  {
    "slugNumber": 47,
    "title": "Top Engineering Colleges in Raipur",
    "slug": "engineering-raipur",
    "description": "List of Top Engineering Colleges in Raipur"
  },
  {
    "slugNumber": 48,
    "title": "Top Engineering Colleges in Bhopal",
    "slug": "engineering-bhopal",
    "description": "List of Top Engineering Colleges in Bhopal"
  },
  {
    "slugNumber": 49,
    "title": "Top Engineering Colleges in Bengaluru",
    "slug": "engineering-bengaluru",
    "description": "List of Top Engineering Colleges in Bengaluru"
  },
  {
    "slugNumber": 50,
    "title": "Top Engineering Colleges in Longowal",
    "slug": "engineering-longowal",
    "description": "List of Top Engineering Colleges in Longowal"
  },
  {
    "slugNumber": 51,
    "title": "Top Engineering Colleges in Pune",
    "slug": "engineering-pune",
    "description": "List of Top Engineering Colleges in Pune"
  },
  {
    "slugNumber": 52,
    "title": "Top Engineering Colleges in Banasthali",
    "slug": "engineering-banasthali",
    "description": "List of Top Engineering Colleges in Banasthali"
  },
  {
    "slugNumber": 53,
    "title": "Top Engineering Colleges in Srinagar",
    "slug": "engineering-srinagar",
    "description": "List of Top Engineering Colleges in Srinagar"
  },
  {
    "slugNumber": 54,
    "title": "Top Engineering Colleges in Amethi",
    "slug": "engineering-amethi",
    "description": "List of Top Engineering Colleges in Amethi"
  },
  {
    "slugNumber": 55,
    "title": "Top Engineering Colleges in Kurukshetra",
    "slug": "engineering-kurukshetra",
    "description": "List of Top Engineering Colleges in Kurukshetra"
  },
  {
    "slugNumber": 56,
    "title": "Top Engineering Colleges in Agartala",
    "slug": "engineering-agartala",
    "description": "List of Top Engineering Colleges in Agartala"
  },
  {
    "slugNumber": 57,
    "title": "Top Engineering Colleges in Gorakhpur",
    "slug": "engineering-gorakhpur",
    "description": "List of Top Engineering Colleges in Gorakhpur"
  },
  {
    "slugNumber": 58,
    "title": "Top Engineering Colleges in Visakhapatnam",
    "slug": "engineering-visakhapatnam",
    "description": "List of Top Engineering Colleges in Visakhapatnam"
  },
  {
    "slugNumber": 59,
    "title": "Top Engineering Colleges in Guntur",
    "slug": "engineering-guntur",
    "description": "List of Top Engineering Colleges in Guntur"
  },
  {
    "slugNumber": 60,
    "title": "Top Engineering Colleges in Solan",
    "slug": "engineering-solan",
    "description": "List of Top Engineering Colleges in Solan"
  },
  {
    "slugNumber": 61,
    "title": "Top Engineering Colleges in Rajpura",
    "slug": "engineering-rajpura",
    "description": "List of Top Engineering Colleges in Rajpura"
  },
  {
    "slugNumber": 62,
    "title": "Top Engineering Colleges in Karaikal",
    "slug": "engineering-karaikal",
    "description": "List of Top Engineering Colleges in Karaikal"
  },
  {
    "slugNumber": 63,
    "title": "Top Engineering Colleges in Tumkur",
    "slug": "engineering-tumkur",
    "description": "List of Top Engineering Colleges in Tumkur"
  },
  {
    "slugNumber": 64,
    "title": "Top Engineering Colleges in Tamil Nadu",
    "slug": "engineering-tamil-nadu",
    "description": "List of Engineering Colleges in Tamil Nadu"
  },
  {
    "slugNumber": 65,
    "title": "Top Engineering Colleges in Delhi",
    "slug": "engineering-delhi",
    "description": "List of Engineering Colleges in Delhi"
  },
  {
    "slugNumber": 66,
    "title": "Top Engineering Colleges in Maharashtra",
    "slug": "engineering-maharashtra",
    "description": "List of Engineering Colleges in Maharashtra"
  },
  {
    "slugNumber": 67,
    "title": "Top Engineering Colleges in Uttar Pradesh",
    "slug": "engineering-uttar-pradesh",
    "description": "List of Engineering Colleges in Uttar Pradesh"
  },
  {
    "slugNumber": 68,
    "title": "Top Engineering Colleges in West Bengal",
    "slug": "engineering-west-bengal",
    "description": "List of Engineering Colleges in West Bengal"
  },
  {
    "slugNumber": 69,
    "title": "Top Engineering Colleges in Uttarakhand",
    "slug": "engineering-uttarakhand",
    "description": "List of Engineering Colleges in Uttarakhand"
  },
  {
    "slugNumber": 70,
    "title": "Top Engineering Colleges in Assam",
    "slug": "engineering-assam",
    "description": "List of Engineering Colleges in Assam"
  },
  {
    "slugNumber": 71,
    "title": "Top Engineering Colleges in Telangana",
    "slug": "engineering-telangana",
    "description": "List of Engineering Colleges in Telangana"
  },
  {
    "slugNumber": 72,
    "title": "Top Engineering Colleges in Jharkhand",
    "slug": "engineering-jharkhand",
    "description": "List of Engineering Colleges in Jharkhand"
  },
  {
    "slugNumber": 73,
    "title": "Top Engineering Colleges in Madhya Pradesh",
    "slug": "engineering-madhya-pradesh",
    "description": "List of Engineering Colleges in Madhya Pradesh"
  },
  {
    "slugNumber": 74,
    "title": "Top Engineering Colleges in Karnataka",
    "slug": "engineering-karnataka",
    "description": "List of Engineering Colleges in Karnataka"
  },
  {
    "slugNumber": 75,
    "title": "Top Engineering Colleges in Gujarat",
    "slug": "engineering-gujarat",
    "description": "List of Engineering Colleges in Gujarat"
  },
  {
    "slugNumber": 76,
    "title": "Top Engineering Colleges in Odisha",
    "slug": "engineering-odisha",
    "description": "List of Engineering Colleges in Odisha"
  },
  {
    "slugNumber": 77,
    "title": "Top Engineering Colleges in Rajasthan",
    "slug": "engineering-rajasthan",
    "description": "List of Engineering Colleges in Rajasthan"
  },
  {
    "slugNumber": 78,
    "title": "Top Engineering Colleges in Punjab",
    "slug": "engineering-punjab",
    "description": "List of Engineering Colleges in Punjab"
  },
  {
    "slugNumber": 79,
    "title": "Top Engineering Colleges in Kerala",
    "slug": "engineering-kerala",
    "description": "List of Engineering Colleges in Kerala"
  },
  {
    "slugNumber": 80,
    "title": "Top Engineering Colleges in Bihar",
    "slug": "engineering-bihar",
    "description": "List of Engineering Colleges in Bihar"
  },
  {
    "slugNumber": 81,
    "title": "Top Engineering Colleges in Andhra Pradesh",
    "slug": "engineering-andhra-pradesh",
    "description": "List of Engineering Colleges in Andhra Pradesh"
  },
  {
    "slugNumber": 82,
    "title": "Top Engineering Colleges in Himachal Pradesh",
    "slug": "engineering-himachal-pradesh",
    "description": "List of Engineering Colleges in Himachal Pradesh"
  },
  {
    "slugNumber": 83,
    "title": "Top Engineering Colleges in Jammu and Kashmir",
    "slug": "engineering-jammu-and-kashmir",
    "description": "List of Engineering Colleges in Jammu and Kashmir"
  },
  {
    "slugNumber": 84,
    "title": "Top Engineering Colleges in Haryana",
    "slug": "engineering-haryana",
    "description": "List of Engineering Colleges in Haryana"
  },
  {
    "slugNumber": 85,
    "title": "Top Engineering Colleges in Tripura",
    "slug": "engineering-tripura",
    "description": "List of Engineering Colleges in Tripura"
  },
  {
    "slugNumber": 86,
    "title": "Top Engineering Colleges in Pondicherry",
    "slug": "engineering-pondicherry",
    "description": "List of Engineering Colleges in Pondicherry"
  },
  {
    "slugNumber": 87,
    "title": "Top Engineering Colleges in Chhattisgarh",
    "slug": "engineering-chhattisgarh",
    "description": "List of Engineering Colleges in Chhattisgarh"
  }
];