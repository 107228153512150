import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Card from "../components/Card"; 
import "./DetailPage.css"; 
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const DetailPage = () => {
  const { category, slug } = useParams(); // Get category and slug from the URL
  const navigate = useNavigate();

  const [pageData, setPageData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // Stores the filtered data
  const [error, setError] = useState("");

  useEffect(() => {
    const loadData = async () => {
      try {
        // Dynamically load the category index
        const categoryIndex = await import(`../data/categories/${category}/${category}Index.js`);
        const data = categoryIndex[`${category}Index`].find((entry) => entry.slug === slug);

        if (data) {
          setPageData(data);

          // Dynamically load the category data
          const categoryDetails = await import(`../data/categories/${category}/${category}Data.js`);
          
          // Filter the data to only include the ones that match the current pageSlug
          const filtered = categoryDetails.default.filter(item => item.pageSlug === slug);
          setFilteredData(filtered);
        } else {
          setPageData(null);
          setFilteredData([]);
          setError("Page not found");
        }
      } catch (err) {
        setError("Error loading page data");
        console.error(err);
      }
    };

    loadData();
  }, [category, slug]); // Reload whenever category or slug changes

  if (error) {
    return <div>{error}</div>;
  }

  if (!pageData || filteredData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Helmet for Dynamic Meta Tags */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta property="og:title" content={pageData.title} />
        <meta property="og:description" content={pageData.description} />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <Navbar/>
      <div className="detail-page">
        <h1>{pageData.title}</h1>
        <p>{pageData.description}</p>

        <div className="card-container">
          {filteredData.map((item) => (
            <Card
              key={item.slugNumber} // Unique key for each card
              name={item.name}
              photo={item.photo}
              source={item.source}
              attribution={item.attribution}
              description={item.description}
              rank={item.rank}
            />
          ))}
        </div>

        <button className="back-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
      <Footer/>
    </>
  );
};

export default DetailPage;
