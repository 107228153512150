export const mainindex = [
  {
    "slugNumber": 1,
    "name": "Sports",
    "slug": "sports",
    "description": "All about top sports players.",
    "image": "NA"
  },
  {
    "slugNumber": 2,
    "name": "Colleges",
    "slug": "colleges",
    "description": "List of top colleges worldwide.",
    "image": "NA"
  }
];